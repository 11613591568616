<section class="hero is-info" (click)="educationHidden = !educationHidden">
    <div class="hero-body">
        <div class="container">
            <h1 class="title has-text-weight-bold">
                Education
            </h1>
            <h3 class="subtitle">
                "An investment in knowledge pays the best interest."

                ― Benjamin Franklin
            </h3>
        </div>
    </div>
</section>

<div *ngFor="let education of educations | async" [hidden]="educationHidden">
    <div class="box column">
        <article class="media">
            <div class="media-left">
                <figure class="image is-96x96">
                    <img [src]="education.logo" alt="logo">
                </figure>
            </div>
            <div class="media-content">
                <div class="content">
                    <p>
                        <strong>{{education.school}}</strong> &nbsp;
                        <small>{{education.company}}</small>
                        <br><br>
                        <span [innerHTML]="education.degree"></span>,
                        <span [innerHTML]="education.field"></span>
                        <span *ngIf="education.specialization">
                            <br>
                            <strong>Specialization:</strong> &nbsp;
                            <span [innerHTML]="education.specialization"></span>
                        </span>
                        <span *ngIf="education.minor">
                            <br>
                            <strong>Minor:</strong> &nbsp;
                            <span [innerHTML]="education.minor"></span>
                        </span>
                    </p>
                    <strong>
                        <small>
                            {{education.gpa}} GPA, {{education.hours}} hours complete
                        </small>
                    </strong>
                </div>
                <nav class="level is-mobile">
                    <div class="level-left">
                        <a class="level-item">
                            {{education.startDate.toDate() | amDateFormat:'YYYY'}} &mdash;
                            {{(education.endDate?.toDate() | amDateFormat: 'YYYY') || "Present"}}
                        </a>
                    </div>
                </nav>
            </div>
        </article>
    </div>
</div>
