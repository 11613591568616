<!--TODO: Add borders around the skill charts-->
<section class="hero is-danger" (click)="skillsHidden = !skillsHidden">
    <div class="hero-body">
        <div class="container">
            <h1 class="title has-text-weight-bold">
                Skills
            </h1>
            <h3 class="subtitle">
                "Any sufficiently advanced technology is indistinguishable from magic."

                ― Arthur C. Clarke
            </h3>
        </div>
    </div>
</section>


<!--<div>-->
<!--    &lt;!&ndash;<div style="width: 40%;">&ndash;&gt;-->
<!--        &lt;!&ndash;<canvas&ndash;&gt;-->
<!--            &lt;!&ndash;baseChart&ndash;&gt;-->
<!--            &lt;!&ndash;[chartType]="'line'"&ndash;&gt;-->
<!--            &lt;!&ndash;[datasets]="chartData"&ndash;&gt;-->
<!--            &lt;!&ndash;[labels]="chartLabels"&ndash;&gt;-->
<!--            &lt;!&ndash;[options]="chartOptions"&ndash;&gt;-->
<!--            &lt;!&ndash;[legend]="true"&ndash;&gt;-->
<!--            &lt;!&ndash;(chartClick)="onChartClick($event)">&ndash;&gt;-->
<!--        &lt;!&ndash;</canvas>&ndash;&gt;-->
<!--    &lt;!&ndash;</div>&ndash;&gt;-->
<!--</div>-->


<div *ngFor="let skill of skills | async" [hidden]="skillsHidden">
    <mat-card>
        <mat-card-title>
            {{skill.name}}
        </mat-card-title>
<!--        <label style="text-align: center">{{skill.name}}</label>-->
        <skill-chart [value]="skill.value" [title]="skill.name"></skill-chart>
    </mat-card>
</div>
