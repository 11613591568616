<h1>Welcome to my resume!</h1>

<p>Please explore what I've been up to.</p>

<experience-list></experience-list>

<br>

<education-list></education-list>

<br>

<skill-list></skill-list>

<br>

<h2>Have Questions?</h2>

<p>
  <i class="fa fa-twitter fa-lg"></i> Let's talk on twitter <a target="blank" href="https://twitter.com/ac_henriques">@ac_henriques</a>
</p>
