<div *ngIf="!(auth.user | async); else alreadyLoggedIn">
    <h1>Please login using one of the methods below</h1>

    <p>
        Or go back to my
        <a href="/">home screen</a>.
    </p>
    <hr>

  <h3>Social Login</h3>

    <button (click)="signInWithGoogle()" class="button btn-social btn-google">
      <i class="fa fa-google fa-lg"></i> Connect Google
    </button>

    <button (click)="signInWithGithub()" class="button btn-social btn-github" disabled>
      <i class="fa fa-github fa-lg"></i> Connect GitHub
    </button>

    <button (click)="signInWithFacebook()" class="button  btn-social btn-facebook" disabled>
      <i class="fa fa-facebook fa-lg"></i> Connect Facebook
    </button>

    <button (click)="signInWithTwitter()" class="button  btn-social btn-twitter" disabled>
      <i class="fa fa-twitter fa-lg"></i> Connect Twitter
    </button>

    <hr>

    <h3>Anonymous Login</h3>

      <button (click)="signInAnonymously()" class="button button-info">
        <i class="fa fa-user-secret fa-lg"></i> Connect Anonymously
      </button>

    <hr>

    <user-form></user-form>

</div>

<ng-template #alreadyLoggedIn>
  <p class="text-success">
    You are logged in <i class="fa fa-thumbs-up"></i>
  </p>
</ng-template>
