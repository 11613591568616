<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav mode="{{navMode}}" opened="true">
        <mat-nav-list>

            <a mat-list-item [routerLink]="'/notes'" (click)="checkSideNav()"> Message Me </a>
            <a mat-list-item [routerLink]="'/uploads'" (click)="checkSideNav()"> File Uploads </a>

            <a mat-list-item (click)="sidenav.close()">Close</a>

            <user-profile></user-profile>

        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
        <notification-message></notification-message>

        <div style="height: 101vh;">

            <div class="content columns">
                <main class="column is-12">
                    <router-outlet></router-outlet>
                </main>
            </div>

        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
