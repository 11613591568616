<div class="box" >
  <div *ngIf="auth.user | async; then authenticated else guest">
          <!-- template will replace this div -->
  </div>
</div>

<!-- User NOT logged in -->
<ng-template #guest class="box">
  <h3>Howdy, GUEST</h3>
  <p class="card-text">Login to contact me...</p>
    <button mat-raised-button color="primary" routerLink="/login">Login</button>
<!--    <button class="button" routerLink="/login">Login</button>-->
</ng-template>

<!-- User logged in -->
<ng-template #authenticated>
  <div *ngIf="auth.user | async as user">
    <h3>Howdy, {{ user.displayName }}</h3>
    <img class="card-img-top" [src]="user.photoURL || 'https://api.adorable.io/avatars/109/fire.png'" width=50px>
    <p class="text-truncate">UID: {{ user.uid }}</p>
      <button mat-raised-button color="warn" (click)="logout()">Logout</button>
<!--      <button class="button" (click)="logout()">Logout</button>-->
  </div>
</ng-template>

