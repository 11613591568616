<h1>Leave me a note</h1>
<p>
  Your message will be displayed publicly
</p>
<hr>

<input [(ngModel)]="content" placeholder="Add your own note" class="input">


<button class="button is-success" (click)="clickHandler()" [disabled]="content?.length < 2 || content?.length > 200">
  Add Note
</button>
<hr>
<div *ngFor="let note of notes | async">
  <note-detail [note]="note"></note-detail>
</div>
