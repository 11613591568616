<mat-toolbar color="primary">
    <mat-toolbar-row>

        <button mat-icon-button>
<!--            <mat-icon  (click)="clickMenu()">menu</mat-icon>-->
            <mat-icon (click)="sideNavService.toggle()">menu</mat-icon>
        </button>

        <a routerLink="/" class="navbar-image">
            <img src="assets/images/profile_pic_square.jpg" width="40px" alt="Andre Henriques"/>
        </a>

        <h1 routerLink="/" class="image-text">Andre Henriques</h1>

<!--        <span class="menu-spacer"></span>-->

<!--        <div>-->
<!--            <a mat-button [routerLink]="'/login'" *ngIf="!(auth.user | async)"> Login </a>-->
<!--            <a mat-button [routerLink]="'/notes'"> Message Me </a>-->
<!--            <a mat-button [routerLink]="'/uploads'"> File Uploads </a>-->
<!--        </div>-->
    </mat-toolbar-row>
</mat-toolbar>











